import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ModalWaiting = (props) => {
		return (
		<>
	    <style jsx>{`
	    		@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
				.noto{
					  font-family: 'Noto Sans JP', sans-serif;
				  }
				.outer{
				  display:flex;
				  align-items: center;
				  justify-content: center;
				  flex-flow: column;
				  justify-content:space-around;
				  margin-top: 50px;
				  margin-bottom: 50px;
				}
				.sk-chase {
				  width: 60px; /* サイズ - 可変 */
				  height: 60px; /* サイズ - 可変 */
				  position: relative;
  				  margin: auto; /*上下マージン - 可変 */
				  animation: sk-chase 2.5s infinite linear both;
				}
				.sk-chase-dot {
				  width: 100%;
				  height: 100%;
				  position: absolute;
				  left: 0;
				  top: 0; 
				  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
				}

				.sk-chase-dot:before {
				  content: '';
				  display: block;
				  width: 25%;
				  height: 25%;
				  background-color: blue; /* ベースの色 - 可変 */
				  border-radius: 100%;
				  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
				}

				.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
				.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
				.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
				.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
				.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
				.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
				.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
				.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
				.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
				.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
				.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
				.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

				@keyframes sk-chase {
				  100% { transform: rotate(360deg); } 
				}

				@keyframes sk-chase-dot {
				  80%, 100% { transform: rotate(360deg); } 
				}

				@keyframes sk-chase-dot-before {
				  50% {
				    transform: scale(0.4); 
				  } 100%, 0% {
				    transform: scale(1.0); 
				  } 
				}

			.flashtext{
				animation: flash 1.5s infinite linear;
				font-family: 'Noto Sans JP', sans-serif;
			}
			@keyframes flash {
			  0%,100% {
			    opacity: 1;
			  }

			  50% {
			    opacity: 0;
			  }
			}
	    `}</style>
		<Modal show={props.show} fullscreen={true} >
			<Modal.Body>
				<div className="outer">
					<div class="sk-chase">
						<div class="sk-chase-dot"></div>
						<div class="sk-chase-dot"></div>
						<div class="sk-chase-dot"></div>
						<div class="sk-chase-dot"></div>
						<div class="sk-chase-dot"></div>
						<div class="sk-chase-dot"></div>
					</div> 
			    </div>
				<div className="outer">
						<p className="small text-center mt20 flashtext">処理中</p>
						<p className="small text-center mt20 flashtext">しばらくお待ちください</p>
			    </div>
			</Modal.Body>
		</Modal>
		</>
    );
};

export default ModalWaiting;