import { useState } from 'react';

import { Routes, Route, Link, BrowserRouter } from 'react-router-dom';
import LIFFHome from './Page/LIFFHome';
import About from './Page/About';
import Video from './Page/Video';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LIFFHome />} />
          <Route path="/about" element={<About />} />
          <Route path="/video" element={<Video />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
