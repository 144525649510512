import { useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Iframe from 'react-iframe';
import {isMobile } from 'react-device-detect';
import ModalWaiting from "../Components/ModalWaiting"

export default function Video() {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const routepath = query.get('path');
  const [url, setURL] = useState("");
  const [modalshow, setModalshow] = useState(true);
  const [errorshow, setErrorshow] = useState(false);

//https://learning.smarteasypos.net/video?path=textboxtryout1
//https://drive.google.com/file/d/12J9DtoZVuXGjv2NOlFajrDywNnO4hco0/view?usp=drive_link
//https://drive.google.com/file/d/12J9DtoZVuXGjv2NOlFajrDywNnO4hco0/preview?usp=drivesdk
//https://drive.google.com/file/d/1Z2oDKCJ9_tlyUvW6mx-_RcPIlCQhbtWc/view?usp=drive_link
//https://drive.google.com/file/d/1Z2oDKCJ9_tlyUvW6mx-_RcPIlCQhbtWc/view?usp=drive_link
//https://drive.google.com/file/d/14IHC9sBc_o2VkVua0QACNgiPrnmiEDbP/view?usp=drive_link
//https://drive.google.com/file/d/1-Hu6YAGTyPvG6mhJSJKbMUJ4IY8ENa3k/view?usp=drive_link
//https://drive.google.com/file/d/1tDxZcggje8KBJMKKhhgFo5XiD1LBuNFQ/view?usp=drive_link
//https://drive.google.com/file/d/1CV5hD7ff2D-2CpqPfJd8azw8JiExuoZf/view?usp=drive_link
//https://drive.google.com/file/d/151G0LZXU4JN7gMy8La62GYsldX7Hw3x2/view?usp=drive_link
//https://drive.google.com/file/d/1-RVidDZWG1osw_PZqHB2FAT7DvmcClrM/view?usp=drive_link


  const driveIDList = [{type:"overview",browser:"12J9DtoZVuXGjv2NOlFajrDywNnO4hco0",mobile:"1es2p7yuQBIj43waGLxJFsuimTVVxJjSs"},//Public
					   {type:"features",browser:"1fQ3SlD4NiysYADX_WPNmWpMmQiH1HvZd",mobile:"15g0DXx8oGZD4kI12HC-VgelkDQ0I7OUD"},
					   {type:"pcpreparation",browser:"16_GKXGyBi0ggPdP1FG2a6P16a_HS5hvj",mobile:"16aFxpve6CAk70YGX4GHkIa3gNGTzRABk"},
					   {type:"coursepreparation",browser:"19w6aIN8P8MLZOOROA9XEdfBj17HLOnR_",mobile:"18XdTBl6rAJBjDscFk2fbvwWd0rRNqHzP"},
					   {type:"schoolrules",browser:"1GCVj_0px_fxXh9XetGF9MkLX3ULPXWBR",mobile:"18Z-oel56fmeM6BVCxuj6SFpT7LlvHVZu"},
					   {type:"pcbegginers",browser:"1vsxAnNyRTJ4VoT13MbvGAf9bIZRdFgXl",mobile:"18qK2_aS_B5z4BAZNvg2r7FUqwbkUr1Uv"},
					   {type:"canvabasic",browser:"1DAhzr9c5D8V2itKPdbqVG8YFaWHBYIpZ",mobile:"191vzGm8CM1Jtmfzc3GI9mwegVWU32AwE"},
					   {type:"canvavideo",browser:"1Dn8rhngxG6IzQC6rUKfdo5PY4oVsJXEk",mobile:"198VFnNry2w0OFiwITRBRoecI7Oqr4q11"},
					   {type:"canvaweb",browser:"1FMuxPE7kf8RV4PDULmzZKlYEY5sEECV5",mobile:"19Czh2tAt34xfJ6ZxIQouQgfW4DE5Of-4"},
					   {type:"personal",browser:"1FQwwis99JlXzhP9dR8UBisPy1PmyGntS",mobile:"19Fr5eDoLacvBXJ3fNcKrdBHWNBxpOuVX"},
					   {type:"canvawebwork",browser:"1CjOhkfQHnb-m_zYXxHT5AGsP-Y9Kbhrt",mobile:"1-5bbMvBxPCjNY05KpzmnzQmMjs7w16Er"},
					   {type:"canvavideowork",browser:"1CH-nOR3Ddv46gd7Vklj3o79TeeRf6KIZ",mobile:"1-AwIIbV7Bk4P8IYofsql8bHSGecTSnOZ"},
					   {type:"whatscanva",browser:"1uHFQntLAiE0qF8nYZqY2PnWIakmOFpIl",mobile:"1ifLpsFON_-N35KPPgolVHKQYcZ7SifLi"},
					   {type:"access",browser:"1BkxllYoyj9d-tsH3BWQLp8ORaRYKuYd-",mobile:"19ruOoOJfrZ89HBSWGkO72mRj9kx3ReBO"},
					   {type:"contact",browser:"1gSFAlfApV6xw5Px5S9nlyTp_fZzZPzLd",mobile:"19sBZo0kS4lZaCxJxLc1G029RpkOm6xK5"},
					   {type:"baby1",browser:"16LPV8ZNBK6LzzGPcPGCVk0pYff64Zp_l",mobile:"1ANHlhGwF9qEhGIyTqsWtm_2STv4sfpMb"},
					   {type:"softbank",browser:"1WZAAJCKEPADCjoQaCznUY7TZNud8R9Jq",mobile:"1ATor9vykEA1MSa5nz4hAVroeRplMCPBv"},
					   {type:"aquarium",browser:"16N7B_574gM_nIQ2ydKrbm-HV4oTx6Fjt",mobile:"1Aw2r9YM0FFFl9S8M4Q59L7jI5gIbYiZ-"},
					   {type:"yamakasa",browser:"14ALAQYU4itc7EQ54RXtoHkr5T70eYLYu",mobile:"1B8YLZDoWFM4LQexKXFi_6Ycez49hUGkY"},
					   {type:"dazaifuasuka",browser:"14IHC9sBc_o2VkVua0QACNgiPrnmiEDbP",mobile:"1-Hu6YAGTyPvG6mhJSJKbMUJ4IY8ENa3k"},
					   {type:"dazaifunara",browser:"1tDxZcggje8KBJMKKhhgFo5XiD1LBuNFQ",mobile:"1CV5hD7ff2D-2CpqPfJd8azw8JiExuoZf"},
					   {type:"dazaifubudda",browser:"151G0LZXU4JN7gMy8La62GYsldX7Hw3x2",mobile:"1-RVidDZWG1osw_PZqHB2FAT7DvmcClrM"},
					   {type:"learningmethod",browser:"1753XwbkyL9uIGrW44jbRP5gmtBd5oxck",mobile:"175zcyHSUEuvTf2Y6SNtdhdqAFnGX-pyK"},//学習サイト
					   {type:"canvafirststep",browser:"1Q_9CQazMxV9bVfTY971GNNCE8BJISzZR",mobile:"17dZlXWXzCn_tpAma16u7uyVy8hn-o594"},
					   {type:"canvatips",browser:"1Qx5bnKAaxBEIyLPy_NchLfCLBRH99wpF",mobile:"17vl0IDbQM9Lg3qmFUcXi9varcG-LEVmo"},
					   {type:"canvabasictryout1",browser:"1R63dN787Gv8CNWFmYxre1w-anDDLBMmK",mobile:"1-0lTsbBcYlnPjMGsW6_bu3A4n6ic24jQ"},
					   {type:"canvabasictryout2",browser:"19S4WR6W-MttfhS_YviUpsnwpBBzZiXlz",mobile:"19MjP0FUiwiDmekBD9ylFKIpqX4oeTs8p"},
					   {type:"canvabasictryout3",browser:"1Z2oDKCJ9_tlyUvW6mx-_RcPIlCQhbtWc",mobile:"19kzN0vG2wwQR15hYuIKWyJs1xXGoymZe"},
					   {type:"canvabasictryout4",browser:"1SgMhIVdEeFkilquDpCirweRoZU-cEz53",mobile:"19qqV1fuHWUx3H_VXJ4497C4HPiHc8q_v"},
					   {type:"textbox",browser:"1IZy5mKXNIb7-EejNu8H06Jr1QIEdVVr6",mobile:"1Ahfcd52aHSBL23BGxKjj6dD2pguarMFf"},
					   {type:"textboxscalemove",browser:"1BqSkOMiHXP9GvTEu_Jb4oWLndsz0mI_W",mobile:"1BlqI6C41bftALCt0BLSAvY1HFpPBK5S9"},
					   {type:"textboxtryout1",browser:"1XoHMTIvR1aph3alJGEyRQIBzQ5v1XbZQ",mobile:"1C80X2AijCqwSEUApCUAyQHRJjFVUvzUM"},
					   {type:"textboxtryout2",browser:"1YLlYqe0ym0oBtJb87rzVqH3Nov9zcK0g",mobile:"1CDyg5dU7_KPS1dHXyXEnERn-O9uKYneu"},
					   {type:"textboxtryout3",browser:"1Z2oDKCJ9_tlyUvW6mx-_RcPIlCQhbtWc",mobile:"1COZApiIVn5Kopw9lkE7N4QDGxOgd3zy9"}
  					  ]
	useEffect(() => {
		if(routepath==="youtube"){
			setURL("https://www.youtube.com/embed/duBSyIF8io4?si=4R98B9Lg19S75t8w");
		}else if(routepath==="youtubeatm"){
		//https://learning.smarteasypos.net/video?path=youtubeatm
			setURL("https://www.youtube.com/embed/yoMsZCwr4dg?si=oWxp8t360NJmiTRm");
		}else{
			let tlist = driveIDList.filter(list => list.type === routepath);
			if(tlist.length > 0){
				let rpath = isMobile ? tlist[0].mobile: tlist[0].browser;
				setURL(`https://drive.google.com/file/d/${rpath}/preview`);
			}else{
				setErrorshow(true);
				setModalshow(false);
			}
		}
		//setURL(`https://drive.google.com/file/d/${rpath}/preview?usp=drivesdk`);
		//setURL(`https://drive.google.com/uc?id=${rpath}&export=download`);
		//setURL(`https://drive.google.com/file/d/${rpath}/view?usp=drive_link`);
	},[])
    const loaded = () => {
		setModalshow(false);
    }
  return (
  			<>
		<style jsx>{`
			* {
			  margin: 0;
			  padding: 0;
			  overflow: hidden;
			  height:100vh;
			}
			iframe {
			  border:none;
			  width:100%;
			  height:100%;
			}
		`}</style>
			{url.length > 0 &&<div className="container">
					<Iframe url={url}
				        width="100%"
				        height="100%"
				        allowfullscreen="allowfullscreen"
				        allow="fullscreen"
				        onLoad={loaded}
				     /></div>}
		     {errorshow && <h1>{routepath} Not Found</h1>}
			<ModalWaiting show={modalshow} />
			</>
  )
}
/***
			{url.length > 0 &&
					<Iframe url={url}
				        width="100%"
				        height="100%"
				        allowfullscreen
				        webkitallowfullscreen
				        mozallowfullscreen
				        onLoad={loaded}
				     />}
			{url.length > 0 &&
					<video src={url}
				        width="100%"
				        height="100%"
				        onLoadedData={loaded}
				     />}
***/
